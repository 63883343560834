@import "tfl-colors.less";
@import "tfl-fonts.less";

// --------------------------------------------------------------------------- Core Variables (bootstrap)
@text-color:                        @black-00;
@font-size-base:                    16px;


// --------------------------------------------------------------------------- Abstraction 

@primary-color:                     @blue-00;
@support-color:                     @green-00;

@nav-link:													@white-00;
@nav-utility:                       @blue-darken-02;
@nav-utility-link:                  @white-00;
@nav-utility-donate-background:     @blue-darken-02;
@nav-utility-alt-background:				@blue-darken-01;
@nav-utility-donate-link:          	@support-color;
@nav-utility-icon:									@blue-lighten-01;

@sign-up-block-background:          @blue-darken-02;
@sign-up-block:                     @blue-lighten-08;

@nav-dropdown-background:           @blue-darken-02;
@nav-dropdown-text:                 @white-00;
@nav-dropdown-link-hover:           @tan-lighten-05;

@search-background:                 @blue-darken-02;
@search-input-background:           @white-00;
@search-icon:                       @white-00;

@title-color:                       @blue-darken-02;

@sermon-link:                       @link-color;
@bold-link:                         @link-color;

@serp-title:                        @link-color;
@serp-link:                         @link-color;

@muted-background:                  @white-darken-01;
@muted-text:                        @taupe-lighten-01;
@muted-text-alt:                    lighten(@link-color, 10%);
@muted-contrast-background:         @taupe-00;
@muted-contrast-text:               @white-darken-03;

@lead-color:												@text-color;

@border-color:                      @white-darken-05;

@sidebar-nav-active:                @blue-00;
@sidebar-nav-color:                 @taupe-00;

@playerbar-background:              darken(@warm-gray-lighten-01, 10%);
@playerbar-color:                   @white-darken-03;
@playerbar-muted-color:             @taupe-lighten-02;
@playerbar-highlight-color:         @blue-lighten-08;

@datepicker-header-background:      @white-darken-03;
@datepicker-header:                 @taupe-darken-01;
@datepicker-prev-next:              @taupe-00;
@datepicker-background:             @white-00;
@datepicker-border:                 @white-00;
@datepicker-daynames-background:    @white-00;
@datepicker-daynames:               @taupe-lighten-02;
@datepicker-days:                   @taupe-00;
@datepicker-highlight-background:   @white-00;
@datepicker-highlight:              @link-color;

@social-share-links:                @taupe-00;

@label-serp-type-background:        @taupe-lighten-02;

@cart-progress:                     @green-00;
@cart-progress-marker:              @taupe-00;

@available-formats-border:                    @white-darken-03;
@available-formats-helper-background:         @white-darken-03;
@available-formats-helper-color:              @white-00;
@available-formats-border-active:             @blue-00;
@available-formats-helper-background-active:  @blue-00;
@available-formats-helper-color-active:       @white-00;


@form-section-title:                @blue-lighten-01;

@price-color:                       @link-color;

@nav-link-hover-bg:                 transparent;

@site-notice-bt:                    @support-color;





@list-left-margin-base: 17px;

/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
// BOOTSTRAP VARIABLES
/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

@panel-primary-text: @text-color;

@brand-primary:                   @primary-color;
@brand-success:                   @green-00;
@brand-secondary:									@brand-success;
@brand-info:                      @blue-lighten-06;
@brand-warning:                   @tan-lighten-05;
@brand-danger:                    @red-00;

//** Global textual link color.
@link-color:                      @blue-00;
//** Link hover color set via `darken()` function.
@link-hover-color:               @blue-lighten-02;

@primary-title:                   @blue-00;
@secondary-title:                 @green-00;

// --------------------------------------------------------------------------- Buttons
// Default
@btn-default-color:               @taupe-darken-01;
@btn-default-bg:                  @white-darken-01;
@btn-default-border:              @white-darken-03;
@btn-primary-bg:                  @blue-00;
@btn-primary-color:               @white-00;
@btn-primary-border:              @blue-00;
@btn-primary-bg-hover:            @blue-lighten-02;
// --------------------------------------------------------------------------- Forms
//** Border color for inputs on focus
@input-border-focus:              @brand-success;

.form-control:focus {
  box-shadow:                     none;
}

// --------------------------------------------------------------------------- Nav tabs
.nav-tabs {
  text-transform:                 uppercase;
  letter-spacing:                 1px;
  margin-top:                     (@line-height-computed * 2);
}

// --------------------------------------------------------------------------- Well
@well-bg:                         @muted-background;
@well-border:                     @muted-background;
.well {
  box-shadow:                     none;
  border-radius:                  0;
}

// --------------------------------------------------------------------------- Tables
@table-bg-hover:                  @white-darken-01;
@table-border-color:              @white-darken-03;


// --------------------------------------------------------------------------- HR
@hr-border:                       @white-darken-05;

// --------------------------------------------------------------------------- Type
//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.5; // 21/14

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px



// --------------------------------------------------------------------------- Font path
@icon-font-path: "/static/fonts/";




// My Custom Bootstrap variables

@tooltip-arrow-color:											@brand-primary;
@tooltip-bg:															@brand-primary;

@screen-xs2-max:    								573px;
@screen-iphone6-max:								667px;
@screen-iphone6plus-max:						736px;


// UI Groupings
.tfl-btn-primary {
  background-color: @btn-primary-bg;
  color: @btn-primary-color;
  border: 0 solid @btn-primary-border;

  &:hover,
  &:focus,
  &:active {
    background-color: @btn-primary-bg-hover;
    color: @btn-primary-color;
    outline: 0;
  }
}

.tfl-btn-submit {
  background-color: @green-00;
  color: @btn-primary-color;
  border: 0 solid @btn-primary-border;

  &:hover,
  &:focus,
  &:active {
    background-color: @green-lighten-02;
    color: @btn-primary-color;
    outline: 0;
  }
}

.tfl-btn-default {
  background-color: @white-darken-01;
  color: @btn-default-color;
  border: 1px solid @white-darken-03;

  &:hover,
  &:focus,
  &:active {
    background-color: @white-00;
    color: @btn-default-color;
    border: 1px solid @white-darken-03;
    outline: 0;
  }
}
