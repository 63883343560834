// @import '../lib/bootstrap-3.2.0/bootstrap.less';
@import "lib/bootstrap-3.2.0/type.less";
@import "lib/bootstrap-3.2.0/tables.less";
@import "lib/bootstrap-3.2.0/forms.less";
@import "lib/bootstrap-3.2.0/buttons.less";
@import "lib/bootstrap-3.2.0/variables.less";
@import "lib/bootstrap-3.2.0/mixins.less";
@import "lib/lesshat/lesshat";
@import "lib/bootstrap-3.2.0/print.less";
@import "base/tfl-variables.less";
@import "includes/my-mixins.less";
@import "lib/bootstrap-3.2.0/utilities.less";
@import "lib/bootstrap-3.2.0/responsive-utilities.less";

// ------------------------------------ Print

body {
  font-family: @font-family-base;
  margin: 20px;
  font-size: 11pt;
}

@media print {
  .print-hidden,
  .base-utility-outer-wrapper,
  .site-notice,
  .modal,
  .promotional-outer-wrapper,
  .footer-nav-subscribe-outer-wrapper,
  .media-player-container,
  .social-wrapper,
  .embed-responsive,
  .sidebar-wrapper,
  .social-share-wrapper2,
  .distribution-options-wrapper,
  .archive-support,
  .post-nav,
  .article-title .ti,
  .legal-social-outer-wrapper,
  .copyright-wrapper a,
  .copyright-wrapper .pipe,
  .footer-logo-wrapper,
  .grid-size,
  #subnav-toggle .section-title,
  #subnav-toggle .sidebar-nav-active,
  .mobile-sign-in .btn,
  .toggle-nav,
  .btn,
  #subnav-toggle .visible-titles .caret,
  .shareaholic-share-buttons-container,
  .shareaholic-canvas,
  #freshwidget-button,
  .mobile-primary-nav,
  .nav-primary-wrapper,
  .day-browser-outer-wrapper,
  .persistent-player,
  .nav-utility-outer-wrapper,
  .ui-datepicker,
  .brand-link,
  .section-title,
  .podcast-display-mini,
  .sidebar-nav,
  .social-share,
  svg,
  #give,
  .sidebar-wrapper {
    display: none !important;
  }

  /* hide journity in print mode*/
  .j-root.j-bumper
  {
    display: none;
  }

  .site-notice {
    bottom: -12200px !important;
    left: -900em;
  }
  a {
    color: @text-color;
    text-decoration: none;
  }
  a[href]:after {
    content: "";
  }

  abbr[title]:after {
    content: "";
  }
  .print-only {
    display: block;
  }

  img.brand-img {
    width: 200px;
  }

  .by-line-wrapper {
    @media (max-width: @screen-sm-max) {
      width: 100%;
    }
  }
  .legal-social-outer-wrapper {
    border-top: none;
  }
  .scripture-reference {
    margin-top: 20px;
  }
  blockquote {
    font-family: @font-family-secondary;
    font-size: 1.2em;
    p {
      line-height: 1.7;
    }
  }
  footer {
    font-size: 0.9em;
    &:before {
      border-top: 1px solid #ddd;
      margin-left: 15px;
      margin-right: 15px;
      content: "";
      display: block;
    }
    * {
      color: @gray !important;
    }
  }
  #give .container {
    width: 6in !important;
  }
  // ---------------------------------------------- Donate Thank You
  .podcast-container-thankyou {

    .thankyou-note {
      .message * {
        font-size: 14px;
      }
    }

    .print-only {
      display: block;
    }
    .header-support h2 {
      margin: 1em 0 0;
    }
    h3.heading {
      margin: 0.5em 0 0;
    }
    font-size: 90%;
    .podcast-container {
      padding-top: 0;
      margin-top: -80px;
      padding-bottom: 0;
    }
    .standard-form {
      .heading {
        margin-: 1em 0 0.1em;
      }
    }
    .main-content-wrapper {
      max-width: 8in !important;
      width: auto !important;
      .row {
        .col-sm-4 {
          .make-xs-column(4);
        }
      }
    }
    .deflist.deflist-horizontal {
      border: none;
      margin-bottom: 0;
      padding-top: 5px;
      padding-bottom: 5px;

      overflow:hidden;
      label
      {
        margin-bottom:0;
      }

      dt {
        padding-top: 0;
        padding-bottom: 0;
        @media (min-width: 500px) {
          float: left;
          width: 33%;
        }
      }
      dd {
        border-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        > p {
          font-size: inherit;
        }
        @media (min-width: 500px) {
          float: left;
          width: 66%;
        }
      }
    }
    .legal-social-outer-wrapper {
      display: none;
    }
    .article-title-wrapper {
      border-bottom: none;
    }
    .header-support {
      display: none;
    }
    .base-utility-outer-wrapper {
      display: none;
    }
  }
  input.devotional {
    border: none;
  }
  .img-responsive {
    max-width: 100%;
  }
  .split-content-support {
    padding-bottom: 20px;
  }
  .devotional-disclaimer {
    color: #999;
    font-size: 9pt;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: none;
    line-height: 1.7;
  }

  .home-blog-featured-image {
    max-width: 300px;
  }
  .series-title-wrapper .tab-pane {
    line-height: 1.95;
  }
  // ---------------------------------------------- Transcript
  #scroll-player,
  .sticky-header,
  .tweet-this {
    display: none;
  }
  .post-wrapper {

    #post-header-follow {
      display:none;
    }

    h3 {
      font-family: @font-family-slab;
      font-weight: 200;
      font-size: 1.5em;
      color: #003859;
    }
    h4 {
      font-family: @font-family-slab;
      font-weight: 200;
      font-size: 1.1em;
      color: #003859;
    }
    h6 {
      border-radius: 3px;
      color: #822b00;
      display: inline-block;
      font-family: @font-family-slab;
      font-size: 1.25em;
      font-weight: 300;
      line-height: 1.58em;
      margin-bottom: 2em;
      padding: 0.75em 1em;
      position: relative;
      text-align: center;
      z-index: 10;

      &::before {
        content: "\201C";
        font-family: @font-family-sans-serif;
        display: inline-block;
        position: absolute;
        top: 56px;
        left: -6px;
        font-size: 7em;
        color: rgba(186, 189, 186, 0.2);
        z-index: -1;
        @media (min-width: 768px) {
          left: -26px;
        }
      }
      &::after {
        content: "\201D";
        font-family: @font-family-sans-serif;
        display: inline-block;
        position: absolute;
        top: 56px;
        right: -6px;
        font-size: 7em;
        color: rgba(186, 189, 186, 0.2);
        z-index: -1;
        @media (min-width: 768px) {
          right: -26px;
        }
      }
    }
  }
  blockquote {
    border: none;
    border-left: 5px solid #e5e5e5;
    padding: 1em 2em;
  }
  .author-photo {
    width: 100px;
    height: 100px;
    margin: 0 10px 0 0;
    float: left;
    border-radius: 4px;
  }
  .topics-wrapper {
    margin: 20px 0;
  }
  .post-id {
    margin: 20px 0;
  }
}
