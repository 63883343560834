.heading-serif {
	font-family: @font-family-slab;
}
.ti() {
	display: inline-block;
  font: normal normal normal 20px/1 "tfl-global"; // shortening font declaration
  font-size: inherit; // can't have font-size inherit on line above, so need to override
  text-rendering: auto; // optimizelegibility throws things off #1094
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); // ensures no half-pixel rendering in firefox
}

.header-chevrons() {

  > a {
	position: relative;
	&:after {
		content: url(/static/images/responsive/icon-nav-down.svg);
		position:relative;
		left:5px;
    top:-2px;
    opacity:0.5;
    @media (max-width: @screen-xs-max) {
      position:absolute;
      left:auto;
      right:20px;
	  }
	}
	  &.open {
	    > a {
	      > i:after {
	        content: "\e607";
	        color: @primary-color;
	      }
	    }
	  }
	}
}
.placeholder(@color) {
	::-webkit-input-placeholder {
	   color: @color;
	}

	:-moz-placeholder { /* Firefox 18- */
	   color: @color;
	}

	::-moz-placeholder {  /* Firefox 19+ */
	   color: @color;
	}

	:-ms-input-placeholder {
	   color: @color;
	}
}

.add-margin-bottom-except-last() {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom:0;
  }
}
.kern-wide {
  letter-spacing: 1px;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn-subscribe, .btn-primary-call, .btn-login {
  background-color: @green-00;
  text-shadow: 0 -1px 0 fade(darken(@support-color, 40%), 20%);
  text-transform: uppercase;
  // box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
  .kern-wide;
  color: @white-00;
  border: none;
  .transition(none);
  background-position: 0;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    background-position: 0 0 !important;
    opacity: 0.7;
    color: @white-00;
  }
}
.day-label, .muted-text {
  color: lighten(@muted-text, 20%);
}
.gradient-whiteout {
	#gradient > .vertical(@start-color: rgba(255,255,255,0); @end-color: rgba(255,255,255,1));
}
/*
.gradient-greenout {
	#gradient > .vertical(@start-color: @green-00; @end-color: @green-darken-01);
}
*/
.gradient-greenout {
  background-color: @green-00;
}

.font-base {
	font-family: @font-family-base;
}
.font-secondary {
	font-family: @font-family-secondary;
}
.btn-support {
  .button-variant(@btn-support-color; @btn-support-bg; @btn-support-border);
}
.svg-position-fix {
	position: relative;
  top: 2px;
}
.grid-size {
  position: fixed;
  bottom: 10px;
  left: 10px;
  color: #ddd;
  background: rgba(0,0,0,.5);
  border-radius: 30px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
}
