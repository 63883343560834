//----------------------------------------------------------------- Sanchez
@font-face {
    font-family: 'Sanchez';
    src: url('/static/fonts/sanchez-reg-webfont.eot');
    src: url('/static/fonts/sanchez-reg-webfont.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/sanchez-reg-webfont.woff2') format('woff2'),
         url('/static/fonts/sanchez-reg-webfont.woff') format('woff'),
         url('/static/fonts/sanchez-reg-webfont.ttf') format('truetype'),
         url('/static/fonts/sanchez-reg-webfont.svg#sanchez_slabextralight_') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'Sanchez';
    src: url('/static/fonts/sanchez-light-webfont.eot');
    src: url('/static/fonts/sanchez-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/static/fonts/sanchez-light-webfont.woff2') format('woff2'),
         url('/static/fonts/sanchez-light-webfont.woff') format('woff'),
         url('/static/fonts/sanchez-light-webfont.ttf') format('truetype'),
         url('/static/fonts/sanchez-light-webfont.svg#sanchez_slabextralight_') format('svg');
    font-weight: @serif-book;
    font-style: normal;

}
//----------------------------------------------------------------- Nunito Sans
@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-ExtraBoldItalic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-Regular.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-Italic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-Bold.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-LightItalic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-Black.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-Light.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-BoldItalic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-SemiBold.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-ExtraBold.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('/static/fonts/nunito-sans/NunitoSans-BlackItalic.woff2') format('woff2'),
        url('/static/fonts/nunito-sans/NunitoSans-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}


//--------------------------------------------- Font Variables

@font-family-sans-serif:                   'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-slab:                         'Sanchez', Georgia, "Times New Roman", Times, serif;
@font-family-serif:                        @font-family-slab;
@font-family-secondary:		                 @font-family-slab;
@font-family-monospace:                     Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-icon:                         'tfl-global';

@serif-book:												500;
@serif-medium:											    600;
@serif-semibold:										    700;

@sans-book:													400;
@sans-medium:												500;
@sans-bold:													600;
